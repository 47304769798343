const styles = {
  container: {
    maxWidth: 550,
    margin: 'auto'
  },
  title: {
    paddingBottom: '.5rem'
  }
}
export default styles
