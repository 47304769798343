const styles = {
  title: {
    paddingBottom: '.5rem'
  },
  tabContainer: {
    width: '100%',
    minHeight: 200
  }
}
export default styles
